import React from "react";
import { get } from "lodash";
import moment from "moment";
import "./workEntryDetails.scss";
import { getQueryParameter } from "../../../util/url";
import {
  getWorkEntryById,
  getWorkEntryHistoryById
} from "../../../api/workEntry";
import { formatDateTime } from "../../../util/util";
import { isEmpty } from "react-select-search/src/Utils";
import { getClientServiceById } from "../../../api/clientService";
import { WE_ACTIONS } from "../../../constants/appConstants";
import MapComponent from "./MapComponent";

class WorkEntryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workEntry: null,
      workEntryHistory: null,
      id: null,
      openNoteHistory: false,
      clientService: null,
      points: null,
      defaultCenter: null
    };
  }

  componentDidMount() {
    const workEntryId = getQueryParameter("id");
    getWorkEntryById(workEntryId).then((we) => {
      this.setState({
        workEntry: we,
        id: workEntryId
      });

      //   serviceStartLongitude serviceStartLatitude
      //   serviceEndLongitude serviceEndLatitude

      if (we?.client?.latitude && we?.client?.longitude) {
        this.setState({
            defaultCenter: {
              lat: we?.client?.latitude,
              lng: we?.client?.longitude
            }
          }
        );
      }

      let coords = [];
      if (we.serviceStartLongitude && we.serviceStartLatitude) {
        coords.push({
          lat: we.serviceStartLatitude,
          lng: we.serviceStartLongitude
        });
      }

      if (we.serviceEndLongitude && we.serviceEndLatitude) {
        coords.push({
          lat: we.serviceEndLatitude,
          lng: we.serviceEndLongitude
        });
      }

      this.setState({
        points: coords
      });

    });
  }

  getService = (serviceRequestId) => {
    getClientServiceById(serviceRequestId).then((cs) => {
      return cs;
    });
  };

  onProfileLinkClick = (id) => {
    this.props.history.push(`/admin/view-staff?id=${id}`);
  };

  onLoadWorkEntryHistory = () => {
    if (!this.state.openNoteHistory) {
      getWorkEntryHistoryById(this.state.id).then((res) => {
        this.setState({
          workEntryHistory: res
        });
      });
    }
  };

  formatDate = (date, simpleDate) => {
    const momentDate = moment(date);
    const hour = momentDate.format("h:mm A");

    if (simpleDate) {
      const date = momentDate.format("MM/DD/YYYY");

      return `${date}, ${hour}`;
    } else {
      const weekDay = momentDate.format("dddd");
      const day = momentDate.format("Do");
      const month = momentDate.format("MMMM");
      const year = momentDate.format("YYYY");

      return `${weekDay}, ${day} ${month} ${year}, ${hour}`;
    }
  };

  render() {
    const { workEntry } = this.state;
    const clientService =
      workEntry && workEntry.serviceRequest ? workEntry.serviceRequest : null;
    const userName =
      workEntry && workEntry.user
        ? workEntry.user.lastName + " " + workEntry.user.firstName
        : "-";
    const clientName =
      workEntry && workEntry.client
        ? workEntry.client.lastName + " " + workEntry.client.firstName
        : "-";
    const elapsedTime = workEntry
      ? workEntry.serviceElapsedTime.toFixed(2)
      : 0.0;
    const grossAmount = workEntry ? workEntry.grossAmount.toFixed(2) : 0.0;
    const netAmount = workEntry ? workEntry.netAmount.toFixed(2) : 0.0;
    const lastEdit =
      workEntry && workEntry.updatedBy != null
        ? moment(new Date()).diff(
          moment(new Date(workEntry.updated_on)),
          "days"
        )
        : "";
    const lastEditTime =
      workEntry && workEntry.updatedBy != null
        ? moment(new Date(workEntry.updated_on))
          .subtract(lastEdit, "days")
          .calendar()
        : "";

    return (
      <div className="col-lg-10 col-md-10 col-sm-10 offset-lg-1 offset-lg-1 offset-lg-1">
        <div
          className="row col-lg-2 col-md-2 col-sm-4"
          onClick={() => {
            this.props.history.goBack();
          }}
        >
          <img src="./images/chevron-left-green.svg" alt="" />
          <span
            style={{
              fontFamily: "Muli",
              color: "#333333",
              fontSize: 15,
              padding: 10
            }}
          >
            Back
          </span>
        </div>
        <div
          className="page-content col-lg-12 col-sm-12"
          style={{ padding: 50 }}
        >
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="client-details">Work Entry Details</div>
            </div>
            <div className="col-3 offset-3 text-right">
              <button
                className="button btn_edit"
                onClick={() => {
                  this.props.history.push(
                    `/admin/edit-timeSheet?id=${this.state.id}`
                  );
                }}
              >
                EDIT
              </button>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-4">
              <div className="notes-header-text">Staff Name</div>
              <div className="notes-title-text">{userName}</div>
            </div>
            <div className="col-4">
              <div className="notes-header-text">Client Name</div>
              <div className="notes-title-text">{clientName}</div>
            </div>
            <div className="col-4">
              <div className="notes-header-text">Service Name</div>
              <div className="notes-title-text">
                {get(clientService, "serviceName", "-")}
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-4">
              <div className="notes-header-text">Service Code</div>
              <div className="notes-title-text">
                {get(clientService, "serviceCode", "-")}
              </div>
            </div>
            <div className="col-4">
              <div className="notes-header-text">Service Unit Length (Hrs)</div>
              <div className="notes-title-text">
                {get(clientService, "serviceUnitLength", "-")}
              </div>
            </div>
            <div className="col-4">
              <div className="notes-header-text">Service Unit Rate</div>
              <div className="notes-title-text">
                {get(clientService, "serviceUnitRate", "-")}
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-3">
              <div className="notes-header-text">Elapsed Time</div>
              <div className="notes-title-text">{elapsedTime}</div>
            </div>
            <div className="col-3">
              <div className="notes-header-text">Used Units</div>
              <div className="notes-title-text">
                {get(workEntry, "unitsUsed", "0")}
              </div>
            </div>
            <div className="col-3">
              <div className="notes-header-text">Gross Amount</div>
              <div className="notes-title-text">{grossAmount}</div>
            </div>
            <div className="col-3">
              <div className="notes-header-text">Net Amount</div>
              <div className="notes-title-text">{netAmount}</div>
            </div>
          </div>
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-12">
              <div className="client-details" style={{ fontSize: 18 }}>
                Date and Time
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-5">
              <img src={"./images/calendar.png"} alt="" />
              <div className="notes-header-text">Start Date and Time</div>
              <div className="notes-title-text">
                {formatDateTime(
                  get(workEntry, "serviceStartTime", null),
                  "MMMM Do YYYY, h:mm a"
                )}
              </div>
            </div>
            <div className="col-5">
              <img src={"./images/calendar.png"} alt="" />
              <div className="notes-header-text">End Date and Time</div>
              <div className="notes-title-text">
                {moment(get(workEntry, "serviceEndTime", null)).format(
                  "MMMM Do YYYY, h:mm a"
                )}
              </div>
            </div>
          </div>
          {/*<div className='row'>*/}
          {/*  <div className='col-12'>*/}
          {/*    <div className="client-details" style={{fontSize: 18}}>Location</div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<hr/>*/}
          {/*<div className='row'>*/}
          {/*  <div className='col-5'>*/}
          {/*    <img src={'./images/pin.png'}/>*/}
          {/*    <div className="title_text">Start Location</div>*/}
          {/*    <div className="header_text"></div>*/}
          {/*  </div>*/}
          {/*  <div className='col-5'>*/}
          {/*    <img src={'./images/pin.png'}/>*/}
          {/*    <div className="title_text">End Location</div>*/}
          {/*    <div className="header_text"></div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<hr/>*/}
          <div className="row" style={{ marginTop: "2em" }}>
            <div className="col-12">
              <div className="client-details" style={{ fontSize: 18 }}>
                Progress
              </div>
            </div>
          </div>
          <hr />
          <div
            className="row"
            style={{ padding: 30, backgroundColor: "#FAFBFC", margin: "2em 0" }}
          >
            <div className="col-4 text-left progress-selection">
              <input
                type="radio"
                name="progress"
                value="increased"
                checked={workEntry && workEntry.progress === 1}
                disabled={true}
              />
              <label className={"radio-text"}>Increased</label>
            </div>
            <div className="col-4 text-left progress-selection">
              <input
                type="radio"
                name="progress"
                value="maintained"
                checked={workEntry && workEntry.progress === 2}
                disabled={true}
              />
              <label className={"radio-text"}>Maintained</label>
            </div>

            <div className="col-4 text-left progress-selection">
              <input
                type="radio"
                name="progress"
                value="decreased"
                checked={workEntry && workEntry.progress === 3}
                disabled={true}
              />
              <label className={"radio-text"}>Decreased</label>
            </div>
          </div>
          <hr />

          <div className="notes-container">
            <div className="row">
              <div className="col-12">
                <div className="notes-header-text">Service Notes</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div
                  className="notes-title-text"
                  style={{ width: "auto", fontSize: 14 }}
                >
                  {get(workEntry, "notes") ? (
                    get(workEntry, "notes")
                  ) : (
                    <span style={{ fontStyle: "italic" }}>No notes added</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <hr />

          {this.state.points &&
            <>
              <div className="row" style={{ marginTop: "2em" }}>
                <div className="col-12">
                  <div className="client-details" style={{ fontSize: 18 }}>
                    Locations
                  </div>
                </div>
              </div>
              <div className="row">
                <span>{JSON.stringify(this.state.points)}</span>
                <div className="col-12">
                  <MapComponent points={this.state.points} defaultCenter={this.state.defaultCenter} />
                </div>
              </div>
              <hr />
            </>
          }

          <div
            className="row col-lg-12 col-md-12 col-sm-12 align-items-center"
            style={{ paddingBottom: "20px" }}
          >
            <div className="col-lg-6 col-md-6 col-sm-12 offset-lg-1 offset-lg-1">
              <div className="header_text">Signature</div>

              <div className="signature-container">
                <div className="row">
                  <div className="ml-auto mr-auto">
                    <img
                      className={{}}
                      src={get(workEntry, "signature", "")}
                      alt="No signature added"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12">
              {workEntry && workEntry.adminVerification && (
                <div className="admin-verified">
                  <div className="row">
                    <span className="col-2 text-right">
                      <img alt="" src="./images/admin-verified.svg" />
                    </span>
                    <div
                      className="col-10 text-left"
                      style={{
                        fontSize: 18,
                        color: "#35CC62",
                        fontWeight: "bold"
                      }}
                    >
                      ADMIN VERIFIED
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-left">
              <span
                style={{ fontSize: 12 }}
                className="title_text"
              >{`Created on ${formatDateTime(
                get(workEntry, "created_on", null),
                "MMMM Do YYYY, h:mm a"
              )} by `}</span>
              <span
                style={{ fontSize: 12 }}
                className="title_text_profile_link"
                onClick={() => {
                  this.onProfileLinkClick(get(workEntry, "createdById", ""));
                }}
              >{`${get(workEntry, "createdBy.lastName", "")} ${get(
                workEntry,
                "createdBy.firstName",
                ""
              )}`}</span>
            </div>
            {workEntry && workEntry.updatedBy && (
              <div className="col-12 text-left">
                <span
                  style={{ fontSize: 12 }}
                  className="title_text"
                >{`Last Modified on ${formatDateTime(
                  get(workEntry, "updated_on", null),
                  "MMMM Do YYYY, h:mm a"
                )} by `}</span>
                <span
                  style={{ fontSize: 12 }}
                  className="title_text_profile_link"
                  onClick={() => {
                    this.onProfileLinkClick(get(workEntry, "updatedById", ""));
                  }}
                >
                  {`${get(workEntry, "updatedBy.lastName", "")} ${get(
                    workEntry,
                    "updatedBy.firstName",
                    ""
                  )}`}
                </span>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-12 text-left">
              {workEntry && workEntry.recordHistory === 1 && (
                <span
                  className="header_text"
                  style={{ color: "#35cc62", fontSize: 12 }}
                >
                  {`Record Imported from Salesforce on ${formatDateTime(
                    get(workEntry, "recordedOn", null),
                    "MMMM Do YYYY, h:mm A"
                  )}`}
                </span>
              )}
              {workEntry && workEntry.recordHistory === 2 && (
                <span
                  className="header_text"
                  style={{ color: "#35cc62" }}
                >{`Record Created by HCBS CRM on ${formatDateTime(
                  get(workEntry, "recordedOn", null),
                  "MMMM Do YYYY, h:mm A"
                )}`}</span>
              )}
            </div>
          </div>
        </div>

        <div className="page-content col-12" style={{ padding: "1em 3.5em" }}>
          <div
            className="flex-history"
            onClick={() => {
              this.setState({
                openNoteHistory: !this.state.openNoteHistory
              });
              this.onLoadWorkEntryHistory();
            }}
          >
            <img
              src={
                this.state.openNoteHistory
                  ? "./images/minus.svg"
                  : "./images/plus.svg"
              }
              alt=""
              style={{ marginRight: "0.7em" }}
            />
            <div
              className="client-details"
              style={{ fontSize: "16px", color: "#35CC62" }}
            >
              View Full Edit History
            </div>
          </div>

          {this.state.openNoteHistory
            ? this.state.workEntryHistory?.map((entry, i) => {
              return (
                <>
                  <hr />

                  <div key={entry.id} style={{ padding: "0 3em" }}>
                    <div className="cont-info-history">
                      <div className="flex-gap">
                        <div className="notes-header-text">Service Code:</div>
                        <span className="notes-title-text">
                            {entry?.serviceRequest?.serviceCode}
                          </span>
                      </div>

                      <div className="flex-gap">
                        <div className="notes-header-text">Service Name:</div>
                        <span className="notes-title-text">
                            {entry?.serviceRequest?.serviceName}
                          </span>
                      </div>

                      <div className="flex-gap">
                        <div className="notes-header-text">Start Time:</div>
                        <span className="notes-title-text">
                            {this.formatDate(entry.serviceStartTime, true)}
                          </span>
                      </div>

                      <div className="flex-gap">
                        <div className="notes-header-text">End Time:</div>
                        <span className="notes-title-text">
                            {this.formatDate(entry.serviceEndTime, true)}
                          </span>
                      </div>

                      <div className="flex-gap">
                        <div className="notes-header-text">
                          Admin Approval:
                        </div>
                        <span className="notes-title-text">
                            {entry.adminVerification ? "Yes" : "No"}
                          </span>
                      </div>
                    </div>

                    <div className="notes-header-text">Services Notes</div>

                    <div
                      className="cont-info-history"
                      style={{ gridTemplateColumns: "1fr", margin: "0" }}
                    >
                      <div
                        className="notes-title-text"
                        style={{ height: "auto", fontSize: 14 }}
                      >
                        {entry.notes ? (
                          entry.notes
                        ) : (
                          <span style={{ fontStyle: "italic" }}>
                              No notes added
                            </span>
                        )}
                      </div>
                    </div>

                    <div
                      className="notes-header-text"
                      style={{
                        textAlign: "end",
                        fontSize: "12px",
                        fontWeight: "bold"
                      }}
                    >
                      {entry.recordAction === WE_ACTIONS.Created
                        ? `Created on ${this.formatDate(
                          entry.recordActionDate
                        )} By ${entry?.recordedBy?.lastName}, ${entry?.recordedBy?.firstName}`
                        : `Updated on ${this.formatDate(
                          entry.recordActionDate
                        )} By ${entry?.recordedBy?.lastName}, ${entry?.recordedBy?.firstName}`}
                    </div>
                  </div>
                </>
              );
            })
            : null}
        </div>
      </div>
    );
  }
}

export default WorkEntryDetails;
