import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { GOOGLE } from "../../../constants/appConstants";

const MapComponent = ({ google, points, defaultCenter }) => {
  // Compute the default center if not provided
  const computedCenter = defaultCenter || {
    lat: (points[0]?.lat + points[1]?.lat) / 2 || 0,
    lng: (points[0]?.lng + points[1]?.lng) / 2 || 0,
  };

  return (
    <div style={{ height: "400px", width: "100%" }}>
      <Map
        google={google}
        zoom={8}
        style={{ width: "100%", height: "100%" }}
        initialCenter={computedCenter}
      >
        {points.map((point, index) => (
          <Marker key={index} position={{ lat: point.lat, lng: point.lng }} />
        ))}
      </Map>
    </div>
  );
};


// Wrapper for Google Maps API
export default GoogleApiWrapper({
  apiKey: GOOGLE.MAPS_API_KEY, // Replace with your API key
})(MapComponent);
